import React from "react"
import Layout from "../components/layout/layout"

const PrivacyPolicyPage = () => (
    <Layout>
        <div className="massive-wrapper">
            <div className="content-wrapper">
                <div className="container">
                    
      <h1>PRIVACY POLICY</h1>
      <p className="c0"><span className="c2">Last updated: July 13, 2020.</span></p>
      <h2>I. INTRODUCTION</h2>
      <p className="c0"><span className="c2">&bdquo;TVFON&rdquo; EOOD (hereinafter referred to as &ldquo;Controller&rdquo;) aims to provide you with the best possible experience when using its mobile application. This is why, the privacy of individuals is taken very seriously by the Controller. Therefore, this Privacy Policy contains useful information about the personal data of individuals is collected, stored, used and disclosed.</span></p>
      <p className="c0"><span className="c5">Welcome to TvVote mobile application (hereinafter referred to as &ldquo;The Application&rdquo; or &ldquo;Application&rdquo;) which is operated by &bdquo;TVFON&rdquo; EOOD,</span><span className="c5 c14">&nbsp;a Bulgarian company, with </span><span className="c5">UIC:</span><span className="c5 c21">&nbsp;</span><span className="c5 c7">205561797</span><span className="c5">, having its seat and registered address at: Republic of Bulgaria, Sofia city, postal code 1164, 26 </span><span className="c5 c18">&ldquo;</span><span className="c5 c24">James Bourchier</span><span className="c5 c18">&rdquo;</span><span className="c2">&nbsp;Boulevard, floor 1, apartment 2.</span></p>
      <p className="c0"><span className="c2">BY USING THIS APPLICATION YOU AGREE TO THE COLLECTION AND PROCESSING OF YOUR PERSONAL DATA IN COMPLIANCE WITH THIS PRIVACY POLICY.</span></p>
      <p className="c0"><span className="c5">PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING THIS APPLICATION AND IF YOU HAVE ANY QUESTIONS ABOUT THIS PRIVACY POLICY, PLEASE CONTACT US AT: </span><span className="c5 c22"><a className="c11" href="mailto:7967680@GMAIL.COM">7967680@GMAIL.COM</a></span><span className="c2">. IF YOU DO NOT AGREE TO ANY OF THE CONDITIONS CONTAINED IN THIS PRIVACY POLICY, YOU SHOULD NOT USE THIS APPLICATION.</span></p>
      <h3>DATA CONTROLLER</h3>
      <p className="c0"><span className="c5">&bdquo;TVFON&rdquo; EOOD is</span><span className="c5 c14">&nbsp;a Bulgarian company, with </span><span className="c5">UIC:</span><span className="c5 c21">&nbsp;</span><span className="c5 c7">205561797</span><span className="c5">, having its seat and registered address at: Republic of Bulgaria, Sofia city, postal code 1164, 26 </span><span className="c5 c18">&ldquo;</span><span className="c5 c24">James Bourchier</span><span className="c5 c18">&rdquo;</span><span className="c2">&nbsp;Boulevard, floor 1, apartment 2.</span></p>
      <h3>SUPERVISORY AUTHORITY</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">Commission for Personal Data Protection</span></p>
      <p className="c9"><span className="c8">Address: Republic of Bulgaria, Sofia 1592, 2 Prof. Tsvetan Lazarov Blvd.</span></p>
      <p className="c9"><span className="c8">Telephone:&nbsp;</span><span className="c2">+3592/91-53-518; +3592/ 91-53-515; +3592/91-53-519</span></p>
      <p className="c9"><span className="c8">Fax:</span><span className="c2">&nbsp;+3592/91-53-525</span></p>
      <p className="c9"><span className="c8">&#1045;-mail:&nbsp;</span><span className="c3"><a className="c11" href="mailto:kzld@cpdp.bg">kzld@cpdp.bg</a></span></p>
      <p className="c9"><span className="c8">Web-site:&nbsp;</span><span className="c3"><a className="c11" href="https://www.google.com/url?q=http://www.cpdp.bg/&amp;sa=D&amp;ust=1594723987166000&amp;usg=AOvVaw3zQ7JNbCQzouaJMeuUhIhA">www.cpdp.bg</a></span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <h2>II. PURPOSE AND SCOPE OF THE PRIVACY POLICY</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">1.1</span><span className="c2">&nbsp;The Controller understands the privacy concerns of the Users of the Application (hereinafter referred to as &ldquo;individuals&rdquo;) regarding the protection of personal data and is committed to protect their personal data by applying all the standards for protection of personal data in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive&nbsp;95/46/EC (hereinafter referred to as &ldquo;GDPR&rdquo;). With this Privacy Policy, the Controller respects the privacy of individuals and undertakes every effort to protect the personal data of individuals against unlawful processing by applying technical and organizational measures, which measures are entirely consistent with state-of-the-art technological developments and provide a level of protection that corresponds to the risks associated with the processing and the nature of the data that should be protected.</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">1.2 &nbsp;</span><span className="c2">With this Privacy Policy and in compliance with the requirements of the GDPR, the Controller provides information on:</span></p>
      <p className="c0"><span className="c2">&ndash; the purpose and scope of this Privacy Policy;</span></p>
      <p className="c0"><span className="c2">&ndash; personal data collected and processed by the Controller;</span></p>
      <p className="c0"><span className="c2">- legal basis for personal data processing;</span></p>
      <p className="c0"><span className="c2">&ndash; purposes of personal data processing;</span></p>
      <p className="c0"><span className="c2">&ndash; period for which the personal data will be stored;</span></p>
      <p className="c0"><span className="c2">&ndash; mandatory and voluntary nature of provision of personal data;</span></p>
      <p className="c0"><span className="c2">&ndash; processing of persona data;</span></p>
      <p className="c0"><span className="c2">&ndash; protection of personal data;</span></p>
      <p className="c0"><span className="c2">&ndash; recipients of personal data;</span></p>
      <p className="c0"><span className="c2">&ndash; rights of individuals;</span></p>
      <p className="c0"><span className="c2">&ndash; procedure to exercise the rights.</span></p>
      <h2>III. DEFINITIONS</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">2.1</span><span className="c2">&nbsp;For the purposes of the GDPR and this Privacy Policy, the following terms shall have the following meaning:</span></p>
      <ol className="c15 lst-kix_list_1-0 start" start="1">
         <li className="c9 c12"><span className="c8">Personal data</span><span className="c2">&nbsp;means any information relating to an identified or identifiable natural person (&lsquo;individual&rsquo;); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person;</span></li>
         <li className="c9 c12"><span className="c8">Processing of personal data&nbsp;</span><span className="c2">means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.</span></li>
         <li className="c9 c12"><span className="c8">Restriction of processing</span><span className="c2">&nbsp;means the marking of stored personal data with the aim of limiting their processing in the future.</span></li>
         <li className="c9 c12"><span className="c8">Profiling</span><span className="c2">&nbsp;means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person&rsquo;s performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements.</span></li>
         <li className="c9 c12"><span className="c8">Controller&nbsp;</span><span className="c2">means the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member&nbsp;State law.</span></li>
         <li className="c9 c12"><span className="c8">Processor</span><span className="c2">&nbsp;means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.</span></li>
         <li className="c9 c12"><span className="c8">Recipient</span><span className="c2">&nbsp;means a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing of those data by those public authorities shall be in compliance with the applicable data protection rules according to the purposes of the processing.</span></li>
         <li className="c9 c12"><span className="c8">Third party</span><span className="c2">&nbsp;means a natural or legal person, public authority, agency or body other than the individual, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.</span></li>
         <li className="c9 c12"><span className="c8">Consent</span><span className="c2">&nbsp;of the individual means any freely given, specific, informed and unambiguous indication of the individuals&rsquo;s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her;</span></li>
         <li className="c9 c12"><span className="c8">Personal data breach</span><span className="c2">&nbsp;means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise processed.</span></li>
      </ol>
      <p className="c1"><span className="c3 c4"></span></p>
      <h2>IV. PRINCIPLES RELATING TO PROCESSING OF PERSONAL DATA</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">3.1</span><span className="c2">&nbsp;The Controller observes the following principles relating to processing of personal data:</span></p>
      <p className="c0"><span className="c2">&ndash; &nbsp;&nbsp;The personal data are processed lawfully, fairly and in a transparent manner in relation to the individual (&lsquo;lawfulness, fairness and transparency&rsquo;);</span></p>
      <p className="c0"><span className="c2">&ndash; The personal data are collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes;</span></p>
      <p className="c0"><span className="c2">&ndash; The personal data are adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed (&lsquo;data minimisation&rsquo;);</span></p>
      <p className="c0"><span className="c2">&ndash; The personal data are accurate and, where necessary, kept up to date (&lsquo;accuracy&rsquo;);</span></p>
      <p className="c0"><span className="c2">&ndash; The personal data are kept in a form which permits identification of individuals for no longer than is necessary for the purposes for which the personal data are processed (&lsquo;storage limitation&rsquo;);</span></p>
      <p className="c0"><span className="c2">&ndash; The personal data are processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organisational measures (&lsquo;integrity and confidentiality&rsquo;).</span></p>
      <h2>V. PERSONAL DATA COLLECTED AND PROCESSED BY THE CONTROLLER</h2>
      <p className="c1"><span className="c3 c4"></span></p>
      <h4>A. Processing of special categories of personal data (&ldquo;sensitive data&rdquo;)</h4>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">4.1</span><span className="c2">&nbsp;The Controller does not collect and record special categories of personal data, such as: personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, as well as genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person&rsquo;s sex life or sexual orientation. Individuals shall not provide such sensitive data to the Controller. If the individual negligently or intentionally provides sensitive data to the Controller, the Controller undertakes to immediately delete such data.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h4>B. Personal data collected directly from individuals</h4>
      <p className="c1"><span className="c3 c4"></span></p>
      <p className="c9"><span className="c8">5.1 </span><span className="c2">In general, the Controller only collect data that allows the individuals to use the Application. When individuals install and use the Application they voluntarily provide personal information such as: telephone and e-mail for Users who are viewers and telephone, e-mail and name of Users who are authors. Users who are viewers can be only physical persons, while Users who are authors can be both legal entities and physical persons.</span></p>
      <p className="c1"><span className="c6 c10"></span></p>
      <h4>C. Personal data collected from third parties</h4>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">6.1</span><span className="c2">&nbsp;The Controller usually does not obtain personal data for individuals from third parties. However, in some cases, if the Controller has a reasonable grounds to suspect any individual of infringing Controller&rsquo;s legal or intellectual property rights, then the Controller will obtain personal data of the suspected individual from public registers or private sources. This data may be processed for the purposes of investigating the infringement and taking legal actions against the infringement. The lawful grounds for processing of the personal data are the legitimate interests pursued by the Controller, which legitimate interests are investigating the infringement and taking legal actions against the infringement.</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <h2>VI. LEGAL BASIS FOR PERSONAL DATA PROCESSING:</h2>
      <p className="c1"><span className="c3 c4"></span></p>
      <p className="c9"><span className="c8">7.1 </span><span className="c2">The legal basis for collecting processing of the personal data of individuals in most of the cases is one of the following:</span></p>
      <p className="c0"><span className="c2">- for the realization of the legitimate interests of the Controller such as understanding how individuals use the Application in order to improve the Application. In this case the Controller ensures that the legitimate interests of the Controller are not overridden by the interests of individuals or fundamental rights and freedoms.</span></p>
      <p className="c0"><span className="c2">- for fulfilling the contractual relationship like providing services.</span></p>
      <h2>VII. PURPOSES OF PERSONAL DATA PROCESSING</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">8.1 The Controller collects and processes the personal data of individuals for the following purposes, namely:</span></p>
      <p className="c0"><span className="c2">&ndash; to provide the services that the Controller offers;</span></p>
      <p className="c0"><span className="c2">&ndash; for the execution of an obligation of the Controller, stipulated by law;</span></p>
      <p className="c0"><span className="c2">&ndash; statistical purposes;</span></p>
      <p className="c0"><span className="c2">&ndash; improving the efficiency and functionality of the Application;</span></p>
      <p className="c0"><span className="c2">&ndash; preparing anonymous statistics on how the Application has been used;</span></p>
      <p className="c0"><span className="c2">- to provide individuals information about their use of the Application and respond to their requests;</span></p>
      <p className="c0"><span className="c2">- communicate with individials.</span></p>
      <p className="c0"><span className="c2">The Controller may not use the personal data of individuals for purposes other than those specified in this section of this Privacy Policy. </span></p>
      <h2>VIII. PERIOD FOR WHICH THE PERSONAL DATA WILL BE STORED</h2>
      <h4>A. Period for which the personal data will be stored</h4>
      <p className="c17"><span className="c6 c4">9.1 Personal data of individuals who have used the Application: </span><span className="c2">Upon stopping using of the Application,</span><span className="c6 c4">&nbsp;</span><span className="c2">the Controller will minimize the personal data and will keep only such a data which is required to keep to comply with laws. In such cases, the Controller will immediately delete all personal data of the individuals who have stopped using the Application after they have stopped using the Application.</span></p>
      <p className="c17"><span className="c6 c4">9.2 </span><span className="c2">Unless otherwise specified, the Controller retains information as long as it is necessary to achieve the purpose described above.</span></p>
      <h4>B. Criteria for determining the period for which the personal data will be stored</h4>
      <p className="c17"><span className="c6 c4">9.3</span><span className="c2">&nbsp;In other situations, not specified above, the Controller will store the personal data of the individual for no longer than needed considering the following criteria, namely: - if the Controller is obliged by a legal norm to continue with the processing of the personal data of the individual; - purpose for storing of the personal data both currently and in the future; - purposes for using of the personal data currently and in the future; - if it is necessary to contact the individual in the future; - if the Controller has any legal ground to continue to process the personal data of the individual; - any other sufficient grounds, like the character of the relationship with the individual.</span></p>
      <h2>IX. MANDATORY AND VOLUNTARY NATURE OF PROVISION OF PERSONAL DATA</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">10.1&nbsp;</span><span className="c2">The personal data required to be provided by the individuals are in accordance with the services offered by the Controller. The provision of personal data by individuals is voluntary. In the event that the individual refuses to provide the personal data:</span></p>
      <p className="c0"><span className="c2">&ndash; the individuals will not be able to use the Application provided by the Controller.</span></p>
      <h2>X. PROCESSING OF PERSONAL DATA</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">11.1</span><span className="c2">&nbsp;The Controller processes the personal data of individuals by means of a set of actions that can be performed by automatic means.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h2>XI. PROTECTION&nbsp;OF PERSONAL DATA</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">12.1</span><span className="c2">&nbsp;The Controller undertakes the appropriate technical and organizational measures to protect the personal data against accidental or unlawful destruction, or against accidental loss, unauthorized access, alteration or dissemination, as well as against other unlawful forms of processing, including the following:</span></p>
      <p className="c0"><span className="c2">&ndash; using only secure and protected servers and folders for storing of the personal data;</span></p>
      <p className="c0"><span className="c2">&ndash; verifying and confirming the identification of the individual inquiring access to his/her personal data before granting access to such personal data.</span></p>
      <p className="c9"><span className="c8">12.2</span><span className="c5">&nbsp; In case you would like to receive detailed information about the technical and organizational measures, please do not hesitate to contact us at </span><span className="c5"><a className="c11" href="mailto:7967680@gmail.com">7967680@gmail.com</a></span><span className="c2">.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h2>XII.&nbsp; RECIPIENTS OF PERSONAL DATA</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">13.1</span><span className="c2">&nbsp;The Controller does not share the personal data of individuals publicly.</span></p>
      <p className="c1"><span className="c2"></span></p>
      <p className="c9"><span className="c8">13.2</span><span className="c2">&nbsp;The Controller has the right to disclose the personal data processed to the following categories of persons, namely:</span></p>
      <ul className="c15 lst-kix_list_4-0 start">
         <li className="c9 c19"><span className="c2">to Individuals to whom the data relate when they exercise the right of access the personal data relating to him/her.</span></li>
         <li className="c9 c19"><span className="c2">to state bodies if provided for in a legal act;</span></li>
         <li className="c9 c19"><span className="c5">to data processors providing services in favor of the Controller&rsquo;s business activities, such as the hosting company, which are subject to a confidentiality obligation, and they have provided sufficient assurance of enforcement appropriate technical and organizational measures in such a way that the processing proceeds in accordance with the requirements of the Regulation and ensures the protection of the rights of individuals. </span></li>
      </ul>
      <p className="c1 c20"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">13.3</span><span className="c2">&nbsp;The Controller does not sell personal data provided by the individual to third parties.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h2>XIII. RIGHTS OF INDIVIDUALS</h2>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right of access by the individual:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.1</span><span className="c2">&nbsp;The individual has the right to obtain from the Controllerconfirmation as to whether or not personal data concerning him or her are being processed. If the Controller processes personal data of the individual the Controller shall provide a copy of the personal data undergoing processing.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right to rectification:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.2</span><span className="c2">&nbsp;The individual has the right to obtain from the Controller without undue delay the rectification of inaccurate personal data concerning him or her. Taking into account the purposes of the processing, the individual has the right to have incomplete personal data completed, including by means of providing a supplementary statement.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right to erasure (&lsquo;right to be forgotten&rsquo;):</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.3</span><span className="c2">&nbsp;The individual has the right to obtain from the Controller the erasure of personal data concerning him or her without undue delay and the Controller has the obligation to erase personal data without undue delay where one of the stated in article 17 of the GDPR grounds applies.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right to restriction of processing:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.4</span><span className="c2">&nbsp;The individual has the right to obtain from the Controller restriction of processing where one of the stated in article 18 of the GDPR grounds applies. If the processing has been restricted, such personal data shall, with the exception of storage, only be processed with the individual&rsquo;s consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the Union or of a Member State. The Controller informs the individual who has obtained restriction of processing before the restriction of processing is lifted.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right to data portability:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.5</span><span className="c2">&nbsp;The individual has the right to receive the personal data concerning him or her, which he or she has provided to the Controller, in a structured, commonly used and machine-readable format and has the right to transmit those data to another controller without hindrance from the Provided to which the personal data have been provided, if the processing is based on consent or on a contract.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right to object:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.6</span><span className="c2">&nbsp;The individual has the right to object on grounds relating to his or her particular situation, at any time to processing of personal data concerning him or her. According to Article 21, Paragraph 4 of the GDPR the right to object shall be explicitly brought to the attention of the individual and shall be presented clearly and separately from any other information. For compliance of this obligation, more information about the right to object, can be found in the section below titled &ldquo;Right to object to processing of personal data&rdquo;.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right of withdrawal of consent:</h3>
      <p className="c1"><span className="c3 c4"></span></p>
      <p className="c9"><span className="c8">14.7</span><span className="c2">&nbsp;The individual has the right at any time to withdraw the consent he has given. Withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal. The order for withdrawal of the consent is specified in Section XIV of this privacy policy. The individual may either withdraw the given consent by choosing the &quot;unsubscribe&quot; option when receiving a newsletter.</span></p>
      <p className="c1"><span className="c2"></span></p>
      <h3>Profiling rights:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.8</span><span className="c2">&nbsp;The individual has the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her or similarly significantly affects him or her.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right to be informed about the personal data breach:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.9</span><span className="c2">&nbsp;The individual has the right to be informed without undue delay about the personal data breach when the personal data breach is likely to result in a high risk to the rights and freedoms of individual.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right to judicial and administrative protection:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">&ndash;&nbsp;Right to lodge a complaint with a supervisory authority</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.10</span><span className="c2">&nbsp;Without prejudice to any other administrative or judicial remedy, the individual has the right to lodge a complaint with the supervisory authority, in particular in the Member State of his or her habitual residence, place of work or place of the alleged infringement if the individual considers that the processing of personal data relating to him or her infringes the GDPR.</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">&ndash;&nbsp;Right to an effective judicial remedy against a supervisory authority</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.11</span><span className="c2">&nbsp;Without prejudice to any other administrative or non-judicial remedy, the individual or legal person has the right to an effective judicial remedy against a legally binding decision of a supervisory authority concerning them. Proceedings against a supervisory authority shall be brought before the courts of the Member&nbsp;State where the supervisory authority is established.</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">&ndash;&nbsp;Right to an effective judicial remedy against the&nbsp;</span><span className="c5">Controller</span><span className="c8">&nbsp;or processor</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.12</span><span className="c2">&nbsp;Without prejudice to any available administrative or non-judicial remedy, including the right to lodge a complaint with a supervisory authority, the individual has the right to an effective judicial remedy where he or she considers that his or her rights under the GDPR have been infringed as a result of the processing of his or her personal data in non-compliance with the GDPR. Proceedings against the Controller or a processor shall be brought before the courts of the Member&nbsp;State where the Controller or processor has an establishment. Alternatively, such proceedings may be brought before the courts of the Member&nbsp;State where the individual has his or her habitual residence.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h3>Right to compensation and liability:</h3>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">14.13</span><span className="c2">&nbsp;Individual who has suffered material or non-material damage as a result of an infringement of the GDPR has the right to receive compensation from the Controller or processor for the damage suffered. Court proceedings for exercising the right to receive compensation shall be brought before the courts of the Member&nbsp;State where the Controller or processor has an establishment. Alternatively, such proceedings may be brought before the courts of the Member&nbsp;State where the individual has his or her habitual residence.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h2>XIV. PROCEDURE TO EXERCISE THE RIGHTS</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">15.1</span><span className="c5">&nbsp;The individual exercises his or her&nbsp;</span><span className="c3">right to withdraw the given consent, right of access, right to rectification, right to erasure, right to restriction of processing, right to data portability, right to object and profiling rights,&nbsp;</span><span className="c2">by submitting a written request to the Controller (either by post at the address specified in the identification of the Controller above or by e-mail), which should contain the following information:</span></p>
      <ol className="c15 lst-kix_list_5-0 start" start="1">
         <li className="c9 c12"><span className="c2">the name, address, and other data necessary for identifying the respective individual;</span></li>
         <li className="c9 c12"><span className="c2">a description of the request;</span></li>
         <li className="c9 c12"><span className="c2">signature, date of submission of the request and e-mail address.</span></li>
      </ol>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">15.2</span><span className="c2">&nbsp;The request shall be filed personally by the individual. The Controller keeps the requests filed by the individuals in a separate register.</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">15.3</span><span className="c2">&nbsp;When the individual exercises the right of access to the personal data relating to him or her the Controller shall verify the identity of the individual before responding to the request. This is necessary to minimize the risk of unauthorized access and identity theft. If the Controller cannot identify the individual from the collected information, then the Controller has the right to require a copy of individual&rsquo;s documentation (such as ID card, driving license, other documents containing personal data that identify the individual) in order to verify the individual&rsquo;s identity.</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">15.4</span><span className="c2">&nbsp;The Controller considers the request and provides the information on action taken on the request of the individual within two months of receipt of the request. This period may be extended by one further month where necessary, taking into account the complexity and number of the requests.</span></p>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">15.5</span><span className="c2">&nbsp;The Controller informs the individual of any such extension within one month of receipt of the request, together with the reasons for the delay. Where the individual makes the request by electronic form means, the information shall be provided by electronic means where possible, unless otherwise requested by the individual.</span></p>
      <p className="c1"><span className="c4 c6"></span></p>
      <p className="c9"><span className="c8">15.6</span><span className="c2">&nbsp;In case the Controller does not take action on the request of the individual, the Controller informs the individual without delay and at the latest within one month of receipt of the request of the reasons for not taking action and on the possibility of lodging a complaint with a supervisory authority and seeking a judicial remedy.</span></p>
      <p className="c1"><span className="c2"></span></p>
      <p className="c9"><span className="c8">15.7</span><span className="c2">&nbsp;The Controller shall communicate any rectification or erasure of personal data or restriction of processing to each recipient to whom the personal data have been disclosed, unless this proves impossible or involves disproportionate effort. The Controller informs the individual about those recipients if the individual requests it.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h2>XV. RIGHT TO OBJECT TO PROCESSING OF PERSONAL DATA</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">16.1</span><span className="c2">&nbsp;The individual has the right to object on grounds relating to his or her particular situation, at any time to processing of personal data concerning him or her. According to Article 21, Paragraph 4 of the GDPR the right to object shall be explicitly brought to the attention of the individual and shall be presented clearly and separately from any other information. For compliance of this obligation, more information about the right to object, will be provided in this section of the Privacy Policy.</span></p>
      <p className="c1"><span className="c2"></span></p>
      <p className="c9"><span className="c8">16.2</span><span className="c2">&nbsp;The individual has the right to object on grounds relating to his or her particular situation, at any time to processing of personal data concerning him or her which is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the Controller or processing is necessary for the purposes of the legitimate interests pursued by the Controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the individual which require protection of personal data, in particular where the individual is a child, including profiling based on any of these provisions. The Controller shall no longer process the personal data unless the Controller demonstrates compelling legitimate grounds for the processing which override the interests, rights and freedoms of the individual or for the establishment, exercise or defense of legal claims. The individual can exercise this right by submitting a written request to the Controller, either by post at the address specified in the identification of the Controller above or by e-mail.</span></p>
      <p className="c1"><span className="c2"></span></p>
      <p className="c9"><span className="c8">16.3</span><span className="c2">&nbsp;Where personal data are processed for direct marketing purposes, the individual has the right to object at any time to processing of personal data concerning him or her for such marketing, which includes profiling to the extent that it is related to such direct marketing. Where the individual objects to processing for direct marketing purposes, the personal data shall no longer be processed for such purposes. The individual can exercise this right by submitting a written request to the Controller, either by post at the address specified in the identification of the Controller above or by e-mail requiring to stop sending him or her marketing information or by clicking the unsubscribe link contained at the bottom of the e-mail the Controller sends to the individual.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h2>XVI. CHANGES TO THE PRIVACY POLICY</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9"><span className="c8">17.1</span><span className="c2">&nbsp;This Privacy Policy may be updated at any time in the future. When this happens, the Controller will notify the individuals. It is therefore advisable to carefully read such notice to make sure that you are familiar with any changes of the Privacy Policy. Using the Application after publishing or sending a notice about the updated Privacy Policy, means that you will be deemed to agree with the changes made.</span></p>
      <p className="c1"><span className="c3 c4"></span></p>
      <h2>XVII. CONTACTS</h2>
      <p className="c1"><span className="c6 c4"></span></p>
      <p className="c9" id="h.gjdgxs"><span className="c8">18.1&nbsp;</span><span className="c5">If you have additional questions about this Privacy Policy, please do not hesitate to contact the Controller at: </span><span className="c5"><a className="c11" href="mailto:7967680@gmail.com">7967680@gmail.com</a></span><span className="c2">.</span></p>
      <div>
         <p className="c27"><span className="c4 c13"></span></p>
         <p className="c26"><span className="c13 c4"></span></p>
      </div>

                </div>
            </div>
        </div>
    </Layout>

)
export default PrivacyPolicyPage